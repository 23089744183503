import Dashboard from "./pages/dashboard";
import Order from "./pages/osorder";
import Inventory from "./pages/inventory";
import Edit from "./pages/inventory/edit";
import Area from "./pages/inventory/area";
import Shop from "./template/Icons/Shop";
import Document from "./template/Icons/Document";
import InventoryIcon from '@mui/icons-material/Inventory';
import UsOrder from "./pages/usorder";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="14px" />,
    component: <Dashboard />,
    noCollapse: true,
    level: ['CA_Van','US_NorCal','US_Seattle','Z_admin'],
  },
  {
    type: "collapse",
    name: "New order",
    key: "tworder",
    route: "/tworder",
    icon: <Document size="14px" />,
    component: <Order />,
    noCollapse: true,
    level: ['CA_Van','US_NorCal','US_Seattle','Z_admin'],
  },
  {
    type: "collapse",
    name: "US order",
    key: "usorder",
    route: "/usorder",
    icon: <Document size="14px" />,
    component: <UsOrder />,
    noCollapse: true,
    level: ['US_NorCal','US_Seattle','Z_admin'],
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    route: "/inventory",
    icon: <InventoryIcon fontSize="small" />,
    component: <Inventory />,
    collapse: [
      {
        name: "Inventory",
        key: "inventory",
        route: "/inventory",
        component: <Inventory />,
        level: ['Z_admin']
      },
      {
        name: "Edit",
        key: "edit",
        route: "/inventory/edit",
        component: <Edit />,
        level: ['Z_admin']
      },
      {
        name: "Area",
        key: "area",
        route: "/inventory/area",
        component: <Area />,
        level: ['Z_admin']
      },
    ],
    level: ['Z_admin'],//'Z_admin'
  },
];
  
export default routes;
  
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Shop size="12px" />,
  //   collapse: [
  //     {
  //       name: "Default",
  //       key: "default",
  //       route: "/dashboards/default",
  //       component: Default,
  //     },
  //     {
  //       name: "Automotive",
  //       key: "automotive",
  //       route: "/dashboards/automotive",
  //       component: Automotive,
  //     },
  //   ],
  // }
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
    order: ['navigator', 'path'],
    lookupQuerystring: 'lng',
}

i18n.use(Backend)
// learn more: https://github.com/i18next/i18next-browser-languageDetector
.use(LanguageDetector)
.use(initReactI18next)
.init({
detection: options,
fallbackLng: 'en',
debug: false,
load: "languageOnly",
react: { 
    useSuspense: false
},
interpolation: {
    escapeValue: false
}
});

export default i18n;
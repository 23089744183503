import { DataStore } from '@aws-amplify/datastore';
import {  OverseasStore } from '../models';

const getCompany = async () => {
    let company = {};
    try {
    const posts = await DataStore.query(OverseasStore);
    for(let i in posts){
        if(posts[i] !== undefined) {
        company[posts[i].Country+'_'+posts[i].Name] = posts[i].Company;
        company[posts[i].Country+'_'+posts[i].Name+'Contract'] = posts[i].ContractNo;
        }
    }
    return company;
    } catch (error) {
    console.log('Error retrieving inv', error);
    }
}


export default getCompany;
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import SoftButton from "../SoftButton";
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

const ExportExcel = ({ excelData, created, qty, final, company, contract }) => {
    const { t } = useTranslation();
    const fileType = 'application/vnd.openxmIformats-officedocument .spreadsheetml. sheet; charset-UTF-8';
    const fileExtension = '.xlsx';
    const borderStyle = {border: {bottom:{style: "thick",color: "000000"}}};
    const fileName = contract+"-"+created.toLocaleString().split('T')[0];
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData,{ origin: 'A4' });
        XLSX.utils.sheet_add_aoa(ws, [["MACU TEA Purchase Order List"]], { origin: "A1" });
        XLSX.utils.sheet_add_aoa(ws, [["Purchase Date",created.toLocaleString().split('T')[0]]], { origin: "A2" });
        XLSX.utils.sheet_add_aoa(ws, [["Total","",qty,parseFloat(final).toFixed(2)]], { origin: "C"+parseInt(excelData.length+5) });
        XLSX.utils.sheet_add_aoa(ws, [["70% Deposit",parseFloat(final*0.7).toFixed(2)]], { origin: "E"+parseInt(excelData.length+6) });
        XLSX.utils.sheet_add_aoa(ws, [["30% Final Payment",parseFloat(final*0.3).toFixed(2)]], { origin: "E"+parseInt(excelData.length+7) });
        XLSX.utils.sheet_add_aoa(ws, [["XINRONG TRADING Co., LTD","","",company]], { origin: "B"+parseInt(excelData.length+9) });
        XLSX.utils.sheet_add_aoa(ws, [["","","",""]], { origin: "B"+parseInt(excelData.length+10) });
        ws["B2"].s = {
            border: {
                right: {style: "thick",color: "000000"},
                left: {style: "thick",color: "000000"},
                top:{style: "thick",color: "000000"},
                bottom:{style: "thick",color: "000000"}
            }
        };
        ws["F"+parseInt(excelData.length+5)].s = borderStyle;
        ws["F"+parseInt(excelData.length+6)].s = borderStyle;
        ws["F"+parseInt(excelData.length+7)].s = borderStyle;

        ws["B"+parseInt(excelData.length+10)].s = borderStyle;
        ws["E"+parseInt(excelData.length+10)].s = borderStyle;
        ws["B"+parseInt(excelData.length+10)].hpx=1000
        ws["B"+parseInt(excelData.length+10)].hpt=1000
        ws['!cols'] = [{ width: 27 }, { width: 50 }, { width: 20 },{ width: 10 }, { width: 15 }, { width: 10 },{ width: 30 }, { width: 20 }, { width: 18 },{ width: 16 }, { width: 10 }, { width: 10 },{ width: 10 }, { width: 10 }, { width: 10 } ];
        ws["P"] = {alignment:{ wrapText: true }}
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
        const data = new Blob ([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <FormControl  variant="outlined">
                <Tooltip title={t('dashboard.Download')}>
                    <SoftButton color="primary" variant="contained" iconOnly onClick={(e)=>{exportToExcel(fileName)}}>
                        <Icon>download</Icon>
                    </SoftButton>
                </Tooltip>
                {/* <FormHelperText id="download-helper-text">{t('dashboard.Download')}</FormHelperText> */}
            </FormControl>
        </>
    )
}

export default ExportExcel;
import DashboardLayout from "../../template/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../template/Navbars/DashboardNavbar";
import getCompany from "../../scripts";
import React,{ useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import SoftBox from "../../components/SoftBox";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import SoftTypography from "../../components/SoftTypography";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import SoftButton from "../../components/SoftButton/index";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SoftInput from "../../components/SoftInput";
import { DataStore } from '@aws-amplify/datastore';
import { OverseasShipping } from '../../models';
import { useNavigate } from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {TextField} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function Area () {
	const location = useLocation();
	const { t } = useTranslation();
	const [area, setArea] = useState([]);
	const [checked, setChecked] = useState([]);
	const [left, setLeft] = useState([]);
	const [right, setRight] = useState([]);
	const [itemsArea , setItemsArea] = useState({});
	const [activeStep, setActiveStep] = useState(0);
	const [currentArea, setCurrentArea] = useState('');
	const [removedItems, setRemovedItems] = useState([]);
	const [originalItems, setOriginalItems] = useState([]);
	const navigate = useNavigate();
	const [searchItem, setsearchItem] = useState('');
	const [newCount, setNewCount] = useState('');
	
	const handleSearchChange = (event) => {
    	setsearchItem(event.target.value); 
  };
	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const steps = [
		t('area.Select Area'),
		t('area.Enter Price'),
	];

	const handleNext = () => {
		let count = 0
		right.map(i=> (
			(i.Price === 0)? count+=1:0
		));
		setNewCount(count)
    	setActiveStep((prevActiveStep) => prevActiveStep + 1);
  	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleInventory = () => {
		navigate('/inventory');
	};

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];
		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
	};

	const numberOfChecked = (items) => intersection(checked, items).length;

	const handleToggleAll = (items) => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items));
		} else {
			setChecked(union(checked, items));
		}
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
		setRemovedItems(not(removedItems, leftChecked));		
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
		rightChecked.map(item=>(
			(originalItems.includes(item))? removedItems.push(item)	:''
		));
		setRemovedItems(removedItems);
	};

	function not(a, b) {
		return a.filter((value) => b.indexOf(value) === -1);
	};
	
	function intersection(a, b) {
		return a.filter((value) => b.indexOf(value) !== -1);
	};
	
	function union(a, b) {
		return [...a, ...not(b, a)];
	};

	useEffect(()=>{
		let areas = [];//'PWWORLD'
		let areaGroup = [];
		getCompany().then(i=>{
			Object.keys(i).map(key=>(
				(!key.includes('Contract'))? areas.push(key): ''
			))
			setArea(areas);
		})
		location.state.item.map(item =>(
			areaGroup[item.ZH] = item.Price
		));
		setCurrentArea(currentArea);
		setItemsArea(areaGroup);
		// eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

	function getAreaItem(e,areaVal) {
		let chosen = [];
		let available = [];
		setCurrentArea(areaVal);
		setRemovedItems([]);
		let i = -1;
		for(let a in itemsArea) {
			if (itemsArea[a] !== null) {
				if( Object.keys(itemsArea[a]).includes(areaVal)) {
					chosen.push({
						Product:a,
						Price:itemsArea[a][areaVal],
						index:i+=1
					});
				}else {
					available.push({
						Product:a,
						Price:0,
						index:i+=1
					});		
				}
			}else {
				available.push({
					Product:a,
					Price:0,
					index:i+=1
				});
			}
		}
		chosen.sort(function (a, b) {
			if(a.Product < b.Product) {return -1}
			if(a.Product  > b.Product) {return 1}
			return 0;
		});
		available.sort(function (a, b) {
			if(a.Product  < b.Product) {return -1}
			if(a.Product  > b.Product) {return 1}
			return 0;
		});
		setRight(chosen);
		setLeft(available);
		setOriginalItems(chosen);
	};
	
	function filteredItems(searchItem){
		const filteredItems = left.filter(element => {
			if (searchItem.length>0) {
				if(element.Product.includes(searchItem)) {
					return element;
				}
			} else {
				return left;
			}
			return '';
		});
		return filteredItems;
	};

	function filteredItems_2(searchItem) {
		const filteredItems_2 = right.filter(element => {
			if (searchItem.length>0) {
				if(element.Product.includes(searchItem)){
					return element;
				}
			} else {
				return right;
			}
			return '';
		});
		return filteredItems_2;
	};

	function getPrice(e,product,currentArea) {
		right.map(i =>(
			(i.Product === product)?i.Price=parseFloat(e.target.value):''
		));
	};

	async function updateItem(e) {
		right.map(i=> {
			location.state.item.map(async item =>{
				if(item.ZH === i.Product) {
					const original = await DataStore.query(OverseasShipping,item.id);
					await DataStore.save(
						OverseasShipping.copyOf(original, updated => {
							if(item.Area.includes(currentArea) && i.Price !== item.Price) {
								updated.Price[currentArea] = i.Price;
							}
							if(!item.Area.includes(currentArea)) {
								updated.Area.push(currentArea);
								updated.Price[currentArea] = i.Price;
							}
						})
					).then(()=>{
						navigate('/inventory');
					});	
				}
			})
			return '';
		});
		removedItems.map( i=> {
			location.state.item.map(async item =>{
				if( i.Product === item.ZH ) {
					const originalItems = await DataStore.query(OverseasShipping,item.id);
					await DataStore.save(
						OverseasShipping.copyOf(originalItems, updated => {
							delete updated.Price[currentArea];
							updated.Area.splice(updated.Area.indexOf(currentArea),1);
						})
					).then(()=>{
						navigate('/inventory');
					});
				}
			})
			return '';
		});
  };

	const customList = (title, items) => (
		<Card>
			<CardHeader
				sx={{ px: 2, py: 1 }}
				avatar={
					<Checkbox
						onClick={handleToggleAll(items)}
						checked={numberOfChecked(items) === items.length && items.length !== 0}
						indeterminate={
							numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
						}
						disabled={items.length === 0}
						inputProps={{
							'aria-label': 'all items selected',
						}}
					/>
				}
				title={title}
				subheader={`${numberOfChecked(items)}/${items.length} `+t('area.selected')}
			/>
			<Divider />
			<List
				sx={{
					width: 350,
					height: 300,
					bgcolor: 'background.paper',
					overflow: 'auto',
					px: 2
				}}
				dense
				component="div"
				role="list"
			>
				{items.map((value,index) => {
					const labelId = `transfer-list-all-item-${value}-label`;
					return (
						<ListItem 
							key={Object.keys(value)+index}
							role="listitem"
							onClick={handleToggle(value)}
							sx={{marginTop: 2}}
						>
							<ListItemIcon >
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										'aria-labelledby': labelId,
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} sx={{marginLeft: -2}} primary={`${value.Product}`} />
						</ListItem>
					);
				})}
			</List>
		</Card>
	);
		 
	return (
		<DashboardLayout>
			<DashboardNavbar />
				<Box sx={{ width: '100%' }}>
					<Stepper activeStep={activeStep} >
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Box>
				{(() => {
					if (activeStep === 0){
						return (
							<>
								<Grid container spacing={3}>
									<Grid item xs={12} md={9}>
										<SoftTypography variant="h6" fontWeight="bold" >{t('area.Please select the area')}:</SoftTypography>
										<FormControl sx={{paddingLeft:'10px'}}>
											<RadioGroup
												row
												aria-labelledby="demo-row-radio-buttons-group-label"
												name="row-radio-buttons-group"
											>
												{area.map(i=>{
													return(
														<FormControlLabel key={i} value={i} control={<Radio />} checked={currentArea === i} onClick={(e)=>getAreaItem	(e,i)} label={i} />
													)
												})}
											</RadioGroup>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={3} display='flex' justifyContent={{xs:'space-between',md:'flex-end'}} alignItems='center'>
										<SoftButton sx={{marginRight:2}} size="medium" variant="contained" color="secondary" onClick={handleInventory}>{t('area.Back')}</SoftButton>
										<SoftButton size="medium" variant="contained" color="primary" onClick={handleNext} disabled={right.length === 0 && left.length ===0 }>{t('area.Next')}</SoftButton>
										<SoftTypography fontSize="small" color="error" hidden={ originalItems.length ===0 || right.length !== 0 }><ErrorIcon/>{t('area.You are going to remove everything')}</SoftTypography>
									</Grid>
								</Grid>

								<SoftBox mb={2} pt={2}>
									<TextField
										variant="outlined"
										value={searchItem}
										onChange={handleSearchChange}
										sx={{ width: { xs: '100%', md: 250 } }}
										placeholder={t('area.Search product')}
									/> 
								</SoftBox>
								<Grid container spacing={2} justifyContent="center" alignItems="center">
									<Grid item>{customList(t('area.Available'), filteredItems(searchItem))}</Grid> {/*.map(i=> Object.keys(i)) */}
									<Grid item>
										<Grid container direction="column" alignItems="center">
											<SoftButton
												sx={{ my: 0.5, width:'100%' }}
												variant="outlined"
												size="small"
												color="primary"
												onClick={handleCheckedRight}
												disabled={leftChecked.length === 0}
												aria-label="move selected right"
											>
												&gt; {t('area.addItem')}
											</SoftButton>
											<SoftButton
												sx={{ my: 0.5, width:'100%' }}
												variant="outlined"
												color="dark"
												size="small"
												onClick={handleCheckedLeft}
												disabled={rightChecked.length === 0}
												aria-label="move selected left"
											>
												&lt; {t('area.delItem')}
											</SoftButton>
										</Grid>
									</Grid>
									<Grid item>{customList(t('area.Chosen'), filteredItems_2(searchItem))}</Grid>
								</Grid>
							</>
						)
					}
				})()}

				{(() => {
					if(activeStep === 1){
						return(
							<>
								<Grid container spacing={3}>
									<Grid item xs={12} md={10}>
										<SoftTypography paddingLeft={{sx:0,md:2}} fontSize="large" variant="h6" fontWeight="bold"><MyLocationIcon color="error"/>{t('area.Current selected area')}: {currentArea}</SoftTypography>
									</Grid>
									<Grid item xs={12} md={2} display="flex" justifyContent={{xs:'space-between',md:'flex-end'}}>
										<SoftButton sx={{marginRight:2}} size="medium" color="secondary" onClick={handleBack}>{t('area.Back')}</SoftButton>
										<SoftButton size="medium" color="success" onClick={(e)=>updateItem(e)}>{t('area.Save')}</SoftButton>  
									</Grid>
								</Grid>

								<SoftBox paddingTop={2} justifyContent="left">
									<SoftTypography sx={{paddingLeft:'14px'}}  hidden={removedItems.length ===0 } fontSize="medium" variant="h6" fontWeight="bold"><DeleteForeverIcon fontSize="medium" color="dark" viewBox="0 0 22 18"></DeleteForeverIcon>
										{t('area.Removed Items')}: {removedItems.length}
									</SoftTypography>
									<Grid container>
										<Grid item xs={6} paddingLeft={{sx:0,md:2}}>
											{removedItems.map((i,index)=> {return (
												<SoftTypography paddingBottom={2} fontSize="medium" key={i+i+index}>{i.Product}</SoftTypography>
											)})}
										</Grid>
									</Grid>
								</SoftBox>
								<SoftBox paddingTop={2} justifyContent="left">
									<SoftTypography sx={{marginTop: -3}} paddingLeft={{sx:0,md:2}} hidden={right.length === 0} fontSize="medium" variant="h6" fontWeight="bold"><CheckBoxIcon fontSize="medium" color="dark" viewBox="0 0 24 18"></CheckBoxIcon>
										{t('area.Selected Items')}: ({t('area.Total')}: {right.length} | {t('area.New added')}: {newCount})
									</SoftTypography>
									<Grid container paddingTop={2}>				
										{
											right.sort((a, b) => a.Price - b.Price).map((i,index) => {
												return (
													<Grid container item xs={12} md={6} key={i+index+i.Product} paddingLeft={{sx:0,md:2}}>
														<Grid item xs={12} md={6}>
															<SoftTypography  fontSize="medium">
																{i.Product}
															</SoftTypography>
														</Grid>
														<Grid item xs={12} md={6} paddingBottom={2} >
															<SoftInput p={2} mx={3} display="flex"  key={i+index+i+i} placeholder="$" error={i.Price === 0} size="small" defaultValue={i.Price} onChange={(e)=>getPrice(e,i.Product, currentArea)}></SoftInput>
														</Grid>
													</Grid>
												)
											})
										}
									</Grid>
								</SoftBox>
							</>
						)
					}
				})()}
		</DashboardLayout>
	);
}
export default Area;
import { Amplify } from 'aws-amplify'
import { withAuthenticator} from '@aws-amplify/ui-react';
import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "./template/Sidenav";
// import Configurator from "./examples/Configurator";
import theme from "./assets/theme";
import "./assets/theme/styles.css";
import routes from "./routes";
import { useSoftUIController, setMiniSidenav } from "./context";//setOpenConfigurator
import brand from "./assets/images/MACU_Logo_Red_NOT.svg";
import Header from "./pages/authentication/header";
import Footer from "./pages/authentication/footer";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const App = ({ user }) => {
  const userGroup = user.signInUserSession.accessToken.payload['cognito:groups'];
  const userType = (userGroup[userGroup.sort().length-1]==='Z_admin'? 'Z_admin':userGroup[0]);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;//openConfigurator
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.level.includes(userType)) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="MACU TEA"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      {layout === "vr" }
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
  components: {
    Header,
    Footer
  }
});
import React,{ useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { TextField } from '@aws-amplify/ui-react';
import DashboardLayout from "../../template/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../template/Navbars/DashboardNavbar";
import { DataStore } from '@aws-amplify/datastore';
import { OverseasShipping } from '../../models';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import SoftBox from '../../components/SoftBox';
import SoftButton from "../../components/SoftButton/index";
import IconButton from '@mui/material/IconButton';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import TableSortLabel from '@mui/material/TableSortLabel';

function Inventory(){
  const [item, setItem] = useState([]);
  const [itemValue, setItemValue] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [delVal, setDelVal] = useState([]);
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(''); 
  const [searchItem, setsearchItem] = useState('');

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a[orderBy], b[orderBy])
      : (a, b) => -descendingComparator(a[orderBy], b[orderBy]);
  };
  
  const descendingComparator = (a, b) => {
    if (b < a) return -1;
    if (b > a) return 1;
    return 0;
  };
  
  const sortedItem = item.sort(getComparator(order, orderBy));
  const filteredItems = sortedItem.filter(item => 
    item.Product.includes(searchItem.toUpperCase()) || 
    item.Code.includes(searchItem.toUpperCase()) || 
    item.ZH.includes(searchItem)
  );

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const handleSearchChange = (event) => {
    setsearchItem(event.target.value);
  };

  const handleClickOpen = (val) => {
    setDelVal(val);
    setOpen(true);
  };

  const handleClose = () => {
    setDelVal([]);
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getItem();
  }, []);//curArea

  async function getItem() {
    let items = [];
    let itemValue = [];
    let category = []
    let uniCategory = [];

    try {
      const posts = await DataStore.query(OverseasShipping);  
      for(let i in posts){
        if(posts[i] !== undefined){
          items.push(posts[i])
          category.push(posts[i].Category)
          uniCategory = [...new Set(category)]
        }
      }
      setItem(items);
      setCategory(uniCategory)
      const column = Object.keys(items[0])
      for (let i in column){
        itemValue.push({id:column[i],label:column[i]});
        if (itemValue[0]['label'] === 'id'){
          itemValue[0]['label'] = 'Action'
        }
      }
      setItemValue(itemValue);
    } catch (error) {
      console.log('Error retrieving inv', error);
    }  
  }

	async function deleteItem() {
    const deleteItem = await DataStore.query(OverseasShipping, delVal);
    await DataStore.delete(deleteItem);
    setsearchItem('');
    setOpen(false);
    setPage(0);
    getItem();
  }

  return(
    <DashboardLayout>
      <DashboardNavbar />
        <Grid container >
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              value={searchItem}
              onChange={handleSearchChange}
              placeholder={t('edit.Search product, code, ZH')}
            />
          </Grid>
          <Grid item xs={12} md={3} pt={2} pl={{md: 6}}>
            <SoftButton sx={{marginRight:2}} color='primary' size='small' variant='outlined' onClick={(e)=>{navigate('/inventory/edit',{state:{item:itemValue, category: category}})}}>{t('edit.Add+')}</SoftButton>
            <SoftButton color='dark' size='small' variant='outlined' onClick={(e)=>{navigate('/inventory/area',{state:{item:item, category: category}})}}>{t('area.Manage')}</SoftButton>
          </Grid>
        </Grid>
        <SoftBox pt={2}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 540 }}>
              <Table stickyHeader aria-label="sticky table"
                sx={{
                    "& .MuiTableHead-root":{
                      display: 'table-header-group',
                    },
                    "& .css-jr1qcz-MuiTableCell-root":{
                      backgroundColor: 'lightGrey'
                    }
                  }}
                >
                <TableHead>
                  <TableRow>
                    {itemValue.slice(0,16).map((column) => (
                      <TableCell
                        key={'tableRow'+column.id}
                        align={'center'}
                        style={{ minWidth: 150, fontWeight: 'bold' }}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={() => {
                            if (column.id !== 'id') {
                              handleSort(column.id);
                            }
                          }}
                          sx={{"& .css-1oqyonh-MuiSvgIcon-root-MuiTableSortLabel-icon":{
                            opacity: '100 !important',
                          }}}
                        >
                          {t('inventory.' + column.label)}
                        </TableSortLabel>
                      </TableCell>
                    ))}                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredItems 
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {                  
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={'tabIndex'+index+row.code} >
                          {itemValue.slice(0,16).map((column) => {
                            let value = row[column.id];
                            if ( value === null || value === 'n/a'){
                              value = 'N/A';
                            }
                            return (
                              typeof value === 'object' ?
                                (<TableCell key={'tableCell'+index+column.id} align={'center'} >
                                  {(() => {
                                    if (column['id'] === 'Price') {
                                      return (
                                        Object.keys(value).sort().map((key, index) => <div key={'cell'+index}>${value[key]}</div>)
                                      )
                                    } else {
                                      return (
                                        Object.values(value).sort().map((val, index) => <div key={'cells'+index}>{val}</div>)
                                      )
                                    }
                                  })()}
                                </TableCell>):
                                (<TableCell key={'tableCell2'+column.id} align={'center'} >
                                  {(() => {
                                    if (column['id'] === 'id') {
                                     let delVal = filteredItems[filteredItems.indexOf(row)];
                                      return <div>
                                        <Tooltip title={t('inventory.Edit')}>
                                          <IconButton aria-label="edit" color='default' onClick={(e)=>{navigate('/inventory/edit',{state:{item:itemValue, category: category, val:filteredItems[filteredItems.indexOf(row)]}})}}>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('inventory.Delete')}>
                                          <IconButton aria-label="delete" color='error' onClick={(e) => handleClickOpen(delVal)}>
                                            <DeleteForeverRoundedIcon />
                                          </IconButton>
                                        </Tooltip>
                                          <Dialog
                                            sx={{
                                              "& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop":{
                                                bgcolor:'#2d2d2d10'
                                              },
                                              "& .css-lzee2o-MuiPaper-root-MuiDialog-paper":{
                                                boxShadow:'unset !important'
                                              }
                                            }}
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                          >
                                            <DialogTitle id="alert-dialog-title">
                                              {t('inventory.This is going to delete the item.')}
                                            </DialogTitle>
                                            <DialogContent>
                                              <DialogContentText id="alert-dialog-description">
                                                {t('Are you sure?')}
                                              </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                              <Button onClick={handleClose} autoFocus>{t('Discard')}</Button>
                                              <Button onClick={deleteItem}>
                                                {t('Yes')}
                                              </Button>                         
                                            </DialogActions>
                                          </Dialog>
                                      </div>
                                    } else {
                                      return <div>{value}</div>
                                    }
                                  })()}
                                </TableCell>)
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                  "& .css-1ui3wbn-MuiInputBase-root-MuiTablePagination-select":{
                    width: 'fit-content !important',
                  }
              }}
            /> 
          </Paper>
        </SoftBox>
    </DashboardLayout>
  );
}

export default Inventory;
import React,{ useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { DataStore } from '@aws-amplify/datastore';
import { OverseasShipping, OverseasOrder, OverseasStore } from '../../models';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import SoftBadge from "../../components/SoftBadge";
import ExportExcel from "../../components/ExportExcel";
import DashboardLayout from "../../template/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../template/Navbars/DashboardNavbar";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function Dashboard() {
  const { user } = useAuthenticator((context) => [context.user]);
  const [orders, updateOrders] = useState([]);
  const [fOrders, updateFOrders] = useState([]);
  const [inv, updateItem] = useState([]);
  const [storeVal, updateStore] = useState({});
  const [oStatus, updateOStatus] = useState([]);
  const area = user.signInUserSession.accessToken.payload['cognito:groups'];
  const [expanded, setExpanded] = useState(false);
  const [orderCountry, setOrderCountry] = useState(['TW']);
  const [curType, setCurType] = useState('TW');
  const [orderSet, setOrderSet] = useState({});
  const [orderRoute, setOrderRoute] = useState('/tworder');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sColor = { pending:'light', processing:'secondary', completed:'dark' };
  const userType = area[area.sort().length-1];
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCountry = (event, country) => {
    setCurType(country);
    setOrderRoute('/'+country.toLowerCase()+'order');
  };

  useEffect(() => {
    getItem();
    getOrders();
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getOrders() {
    try {
      const posts = await DataStore.query(OverseasOrder);
      let val = [];
      let remaining = '';
      let country = [];
      let curMonth = new Date().getMonth()+1;
      let today = new Date().getDate();
      posts.sort(function (a, b) {
        if(a.updatedAt > b.updatedAt) {return -1}
        if(a.updatedAt < b.updatedAt) {return 1}
        return 0;
      });

      for(let i in posts) {
        if(posts[i] !== undefined) {
          let orderval = posts[i].Name.split('@');
          let sVal = [...posts[i].Status];
          
          sVal.sort(function (a,b) {
            if(a.split('@')[1] < b.split('@')[1]) {return -1}
            if(a.split('@')[1] > b.split('@')[1]) {return 1}
            return 0;
          })
          
          let oDate = new Date(posts[i].updatedAt).getDate();
          let oMonth = new Date(posts[i].updatedAt).getMonth()+1;
          if (oMonth === curMonth) {
            remaining = (oDate <15)? 15-today : 30-today;
            if (oDate===30 && today ===31 && remaining <1) {remaining = 15}
            else if (oDate===30 && remaining <1) {remaining = 16}
            else if (oDate===31 && remaining <1) {remaining = 15}
            else if (oDate===31 || oDate===30) {remaining = 15 - today}
          }
          
          if(orderval[0]===user.attributes.name) {
            val.push({
              id:posts[i].id,
              orderby:orderval[0],
              orderarea:orderval[1],
              orders:posts[i].Code,
              created_at:posts[i].updatedAt,
              quantity:posts[i].Qty,
              status:sVal,
              amount:posts[i].Amount,
              remaining:remaining,
              orderType:posts[i].OrderType
            });
          } else if(area.includes('Z_admin')) {
            val.push({
              id:posts[i].id,
              orderby:orderval[0],
              orderarea:orderval[1],
              orders:posts[i].Code,
              created_at:posts[i].updatedAt,
              quantity:posts[i].Qty,
              status:sVal,
              amount:posts[i].Amount,
              // editable:true,
              remaining:remaining,
              orderArea:posts[i].Name.split('@')[1],
              orderType:posts[i].OrderType
            });
          }
        }
      }
      [...new Set(val.map(item=>item.orderType))].map(item=>{
        return country.push(item); 
      });

      if (userType.slice(0,2) === 'US' || userType.slice(0,1) === 'Z') {setOrderCountry(['TW','US'])} 

      let orders = {};
      country.map(item => {
        return orders[item] = 0;
      });
      val.map((item) => {
        return orders[item.orderType] +=1;
      });
      setOrderSet(orders);
      updateOrders(val);
      updateFOrders(val);

      let orderStatus = val.map(item => {return item.status[item.status.length-1].split('@')[0]});
      updateOStatus(orderStatus);
    } catch (error) {
      console.log('Error retrieving order', error);
    }
  };

  const filterItem = (curcat) => {
    if (curcat.length>0) {
      const newItem = orders.filter((newVal) => {
        return curcat.includes(newVal.status[newVal.status.length-1].split('@')[0]);
      });
      updateFOrders(newItem);
    } else {
      updateFOrders(orders);
    }
  };

  async function getItem() {
    try {
      const posts = await DataStore.query(OverseasShipping);
      let userInv = [];
      posts.sort(function (a, b) {
        if(a.Code < b.Code) {return -1}
        if(a.Code > b.Code) {return 1}
        return 0;
      });
      
      for(let i in posts) {
        if(posts[i] !== undefined) {
          if (userType==='Z_admin') {
            userInv.push(posts[i]);
          } else if (posts[i].Area.includes(area[0])) {
            userInv.push(posts[i]);
          } else if (posts[i].Area.includes(area[0].split('_')[0])) {
            userInv.push(posts[i]);
          } 
        }
      }
      updateItem(userInv);
    } catch (error) {
      console.log('Error retrieving inv', error);
    }
  };

  async function getCompany() {
    let company = {};
    try {
      const posts = await DataStore.query(OverseasStore);
      for(let i in posts){
        if(posts[i] !== undefined) {
          company[posts[i].Country+'_'+posts[i].Name] = posts[i].Company;
          company[posts[i].Country+'_'+posts[i].Name+'Contract'] = posts[i].ContractNo;
        }
      }
      updateStore(company);
    } catch (error) {
      console.log('Error retrieving inv', error);
    }
  };

  async function updateOrder(orderid,status) {
    const original = await DataStore.query(OverseasOrder, orderid);
    await DataStore.save(
      OverseasOrder.copyOf(original, updated => {
        if(updated.Status.length===2 && status==='processing') {
          updated.Status[1]='processing@'+new Date().toISOString();
        } else {
          if(status==='completed') {updated.Status.push('completed@'+new Date().toISOString())}
          else if(status==='processing') {updated.Status.push('processing@'+new Date().toISOString())}
          else {updated.Status[0]='pending@'+new Date().toISOString()}
        }
      })
    );
    getOrders();
  };

  function searchOrder(o) {
    let eVal = [];
    let filtered = []
    fOrders.map(item => {
      if(item.orderType === curType) {
        filtered.push(item);
      }
      return '';
    });
    let sOrder = filtered[o].orders.slice().sort();
    for(let i in sOrder) {
      let oitem = sOrder[i].split('@');
      let icode = oitem[0];
      let iqty = oitem[1];
      let iprice = oitem[2];
      for(let p in inv) {
        if(icode === inv[p].Code) {
          let cSize = (inv[p].CartonSize) ? inv[p].CartonSize.split('*') : 0;
          eVal.push({
            Category:inv[p].Category,
            ProductName:inv[p].Product,
            Code:inv[p].Code,
            UnitPrice:parseFloat(iprice/iqty).toFixed(2),
            OrderingQty:parseInt(iqty),
            Amount:parseFloat(iprice).toFixed(2),
            ProductZH:inv[p].ZH,
            PackagingSize:inv[p].Size,
            ExpiryDate:(parseInt(inv[p].Expiry)>0) ? inv[p].Expiry+"months" : 'N/A',
            Unit:inv[p].Unit,
            Nw:inv[p].NW,
            Gw:inv[p].GW*parseInt(iqty),
            CBM:(parseInt(cSize[0])*parseInt(cSize[1])*parseInt(cSize[2])*parseInt(iqty))/1000000 || '0',
            NetWeight:inv[p].NW*parseInt(iqty),
            CartonSize:inv[p].CartonSize,
            Note:inv[p].Note,
            orderarea:filtered[o].orderarea,
            orderid:filtered[o].id,
            // orderby:fOrders[o].orderby,
            // updated_at:fOrders[o].created_at,
          });
        }
      }
    }
    return eVal;
  };

  function DeatilCard() {
    let filtered = [];
    fOrders.map((item,index) => {
      if(item.orderType === curType) {
        filtered.push(item);
      }
      return '';
    });
    return filtered.map((item,index) => (
      <Accordion key={item.id} expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"panel"+index+"bh-content"+index}
          id={"panel"+index+"bh-header"}
        >
          <SoftTypography  sx={{ width: '33%', flexShrink: 0 }} variant="h6" fontWeight="medium">
            {new Date(item.created_at).toLocaleString()}
            &nbsp; 
            {item.status[item.status.length-1].split('@')[0]==='pending' && item.remaining>0?(<SoftBadge variant="contained" badgeContent={t(item.remaining+' '+t('dashboard.Remaining'))} color='silver' size="xs" container />):null}
          </SoftTypography>
          <SoftTypography variant="h6" fontWeight="medium" sx={{ color: 'text.secondary' }}>
            <Icon variant="fill">description</Icon> 
            {item.id.split('-')[0]} - {userType==='Z_admin'?(item.orderarea+' -'):null} {item.orderby} &nbsp;
            <SoftBadge variant="gradient" badgeContent={t('dashboard.'+item.status[item.status.length-1].split('@')[0])} color={sColor[item.status[item.status.length-1].split('@')[0]]} size="xs" container />
          </SoftTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} key={item.id+index}>
          <Grid item xs={12} display='flex' justifyContent='flex-end'>
              {item.status[item.status.length-1].split('@')[0]==='pending' && item.remaining>0 ? (//!=='completed'
                <FormControl  variant="outlined">
                  <Tooltip title={t('dashboard.Edit')}>
                    <SoftButton color="dark" variant="outlined" iconOnly onClick={(e)=>{navigate(orderRoute,{state:searchOrder(index)})}}>
                      <Icon>edit</Icon>
                    </SoftButton>
                  </Tooltip>
                </FormControl>
              ):null}
              &nbsp;
              {userType==='Z_admin' && item.status[item.status.length-1].split('@')[0] === 'pending'?(
                <FormControl  variant="outlined">
                  <Tooltip title={t('dashboard.Processing')}>
                    <SoftButton color="info" variant="outlined" iconOnly onClick={(e)=>{updateOrder(item.id,'processing')}}>
                      <Icon>done</Icon>
                    </SoftButton>
                  </Tooltip>
                </FormControl>
              ):null}
              &nbsp;
              {userType==='Z_admin' && item.status[item.status.length-1].split('@')[0] === 'processing'?(
                <FormControl  variant="outlined">
                  <Tooltip title={t('dashboard.Pending')}>
                    <SoftButton color="secondary" variant="outlined" iconOnly onClick={(e)=>{updateOrder(item.id,'pending')}}>
                      <Icon>undo</Icon>
                    </SoftButton>
                  </Tooltip>
                </FormControl>
              ):null}
              &nbsp;
              {userType==='Z_admin' && item.status[item.status.length-1].split('@')[0] === 'processing'?(
                <FormControl  variant="outlined">
                  <Tooltip title={t('dashboard.Completed')}>
                    <SoftButton color="error" variant="outlined" iconOnly onClick={(e)=>{updateOrder(item.id,'completed')}}>
                      <Icon>verified</Icon>
                    </SoftButton>
                  </Tooltip>
                </FormControl>
              ):null}
              &nbsp;
              <ExportExcel excelData={searchOrder(index)} created={item.created_at} qty={item.quantity} final={item.amount} company={storeVal[item.orderarea]} contract={storeVal[item.orderarea+'Contract']}/>
            </Grid>
            <Grid item xs>
              <SoftBox lineHeight={1.5}>
                <SoftTypography display="block" variant="h6" fontWeight="regular">
                  {t('Item')}
                </SoftTypography>
                <SoftTypography variant="h6" fontWeight="bold">
                  {item.orders.length}
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs>
              <SoftBox lineHeight={1.5}>
                <SoftTypography variant="h6" fontWeight="regular">
                  {t('Quantity')}
                </SoftTypography>
                <SoftTypography variant="h6" fontWeight="bold">
                  {item.quantity.toLocaleString()}
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs>
              <SoftBox lineHeight={1.5}>
                <SoftTypography variant="h6" fontWeight="regular">
                  {t('Total amount')}
                </SoftTypography>
                <SoftTypography variant="h6" fontWeight="bold">
                  ${parseFloat(item.amount).toLocaleString(undefined, {minimumFractionDigits: 2})}
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
          <Divider />
          {searchOrder(index).map((item,index)=>{
            return <SoftBox
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              key={item.ProductName+index+item.OrderingQty}
            >
              <Grid container spacing={3} key={item.ProductName+index+item.OrderingQty} style={{paddingTop:12}}>
                <Grid item xs>
                  <SoftTypography display="block" variant="h6" fontWeight="regular">
                    {item.ProductName.charAt(0) + item.ProductName.toLowerCase().slice(1)}&nbsp;
                    {item.ProductZH}
                  </SoftTypography>
                </Grid>
                <Grid item xs>
                  <SoftTypography variant="h6" fontWeight="regular">
                    {item.OrderingQty}
                  </SoftTypography>
                </Grid>
                <Grid item xs>
                  <SoftTypography variant="h6" fontWeight="regular">
                    ${parseFloat(item.Amount).toLocaleString(undefined, {minimumFractionDigits: 2})}
                  </SoftTypography>
                </Grid>
              </Grid>
            </SoftBox>
          })}
        </AccordionDetails>
      </Accordion>
    ));
  };

  function NoCard() {
    return <div>
      <SoftBox p={2} mx={3} display="flex" justifyContent="center">
        <SoftBox
          display="grid"
          color="error"
        >
          <Icon fontSize='large'>add_circle</Icon>
        </SoftBox>
      </SoftBox>
      <SoftBox pb={6} px={2} pt={6} textAlign="center" lineHeight={1.25}>
        <SoftTypography variant="h6" fontWeight="bold">
          {t('dashboard.No order')}
        </SoftTypography>
        <br/>
        <SoftTypography variant="h6" color="text" fontWeight="bold">
          {t('dashboard.No order msg')}
        </SoftTypography>
        <Divider />
        <Link to={orderRoute}>
          <SoftButton color="primary" variant="outlined" size="small">{t('dashboard.Create new order')}</SoftButton>
        </Link>
      </SoftBox>
    </div>
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container pt={2} px={2}>
              <Grid item xs={12} md={6}>
                <SoftTypography variant="h5" fontWeight="bold">
                  {t('dashboard.Order history')}&nbsp;
                  ({orderSet[curType] || 0} {t('dashboard.orders')})
                  <Tooltip title={t('dashboard.Order rule')}>
                    <Icon color='dark' fontSize='inherit'>info</Icon>
                  </Tooltip>
                </SoftTypography>
              </Grid>
              <Grid item xs={12} md={6} marginTop={{xs:1, md:0}} display='flex' justifyContent={{ md: 'flex-end' }} hidden={userType === 'CA_Van'}>
                <SoftTypography variant="h6" fontWeight="bold">{t('inventory.ShipFrom')}:&nbsp;
                <ToggleButtonGroup
                  color="primary"
                  value={curType}
                  exclusive
                  onChange={handleCountry}
                  aria-label="orderCountry"
                  sx={{borderColor:'white'}}
                >
                  {orderCountry.sort().map((label, index) => {
                    let option = [];
                    option.push(<ToggleButton key={index+label} value={label}>{label}</ToggleButton>);
                    return option;
                  })}
                </ToggleButtonGroup>
                </SoftTypography>
              </Grid>
            </Grid>
            <Grid container pt={2} px={2}>
              <Grid item xs={12} md={6}>
                <Stack width={{ xs: '100%', md:210 }}>
                  <Autocomplete
                    multiple
                    id="status-filter"
                    options={[...new Set(oStatus)].map((Val, id) => {return Val})}
                    getOptionLabel={(option) => t('dashboard.'+option)}
                    defaultValue={[]}
                    onChange={(e,option) => filterItem(option)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={t('dashboard.'+option)} {...getTagProps({ index })} data-value={option}/>
                      ))
                    }
                    renderInput={(params) => 
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={t('dashboard.Status')} 
                        sx={{
                          "& .css-kd6prr-MuiAutocomplete-root, .MuiAutocomplete-tag[data-value='pending']": {
                            bgcolor: "#ced4da",
                          },
                          "& .css-kd6prr-MuiAutocomplete-root, .MuiAutocomplete-tag[data-value='processing']": {
                            bgcolor: "#627594",
                          },
                          "& .css-kd6prr-MuiAutocomplete-root, .MuiAutocomplete-tag[data-value='completed']": {
                            bgcolor: "#141727",
                          }
                        }}
                      />
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} paddingTop={{xs:2}} display='flex' justifyContent={{ md: 'flex-end' }}>
                <Link to={orderRoute}>
                  <SoftButton color="primary" variant="outlined" size="small">{t('dashboard.Create new order')}</SoftButton>
                </Link>
              </Grid>  
            </Grid>    
            <Divider/>        
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {orderSet[curType] === undefined ? (
                <NoCard />
              ):(
                <DeatilCard />
              )}
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Dashboard;
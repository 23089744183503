// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { USShipping, OverseasStore, OverseasShipping, OverseasOrder } = initSchema(schema);

export {
  USShipping,
  OverseasStore,
  OverseasShipping,
  OverseasOrder
};
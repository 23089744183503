import DashboardLayout from "../../template/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../template/Navbars/DashboardNavbar";
import Button from '@mui/material/Button';
import React,{ useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import SoftBox from "../../components/SoftBox";
import Box from '@mui/material/Box';
import { DataStore } from '@aws-amplify/datastore';
import { OverseasShipping } from '../../models';
import { useLocation } from "react-router-dom";
import SoftTypography from "../../components/SoftTypography";
import Grid from '@mui/material/Grid';
import SoftButton from "../../components/SoftButton";
import SoftInput from "../../components/SoftInput";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import getCompany from "../../scripts";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from "@mui/material/FormHelperText";
import Icon from '@mui/material/Icon';

function Edit() {
	const location = useLocation();
	const { t } = useTranslation();
	const [area, setArea] = useState([]);
	const [inputVal, setInputVal] = useState({});
	const [checked, setChecked] = useState(true);
	const [qtyError, setQtyError] = useState(false);
	const inputRef = useRef();
	const inputMin = useRef();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [priceSet, setPriceSet] = useState([]);
	const [shipFrom, setShipFrom] = useState([]);
	const handleChange = (event) => {
		setChecked(event.target.checked);
		let newVal = {...inputVal};
		if (event.target.checked) {
			if (location.state.val !== undefined) {		
				newVal.Moq = [location.state.val['Moq'][0],location.state.val['Moq'][location.state.val['Moq'].length-1]];//allow user to switch from multiplier to minmax
				inputRef.current.value = newVal.Moq[1];
				inputMin.current.value =  newVal.Moq[0];
			}
			else {
				newVal.Moq = [0,200];
				inputRef.current.value = 200;
				inputMin.current.value = 0;
			}
		} else {
			inputRef.current.value = 1;
			inputMin.current.value = 0;
			newVal.Moq = [newVal.Moq[0]];
		}
		setInputVal(newVal);
	};

	const handleClickOpen = () => {
    setOpen(true);
  };

	const handleClose = () => {
	setOpen(false);
	};

	useEffect(()=>{
		getShipFrom();
		let areas = [];
		inputRef.current.value = (location.state.val !== undefined)? location.state.val['Moq'][1]:200;
		inputMin.current.value = location.state.val !== undefined?  location.state.val['Moq'][0]: 0;

		getCompany().then(i=>{
			Object.keys(i).map(key=>(
				(!key.includes('Contract'))? areas.push(key):''
			));
			setArea(areas);
		});
		let input = {};
		location.state.item.slice(1,16).map(item=>{
			switch(item.id){
				case 'Moq':
					input[item.id] = (location.state.val !== undefined)? location.state.val.Moq:[0,200];
					break;
				case 'Area':
					input[item.id] = (location.state.val !== undefined)? location.state.val.Area:[];
					break;
				case 'Price':
					input[item.id] = (location.state.val !== undefined)? location.state.val.Price:{};
					break;
				case 'Category':
					input[item.id] = (location.state.val !== undefined)? location.state.val.Category:'Uniform';
					break;
				default:
					input[item.id] = (location.state.val !== undefined)? location.state.val[item.id]:null;
					break;
			}
			return '';
		});

		if(location.state.val !== undefined) {
			setPriceSet(location.state.val['Moq']);
			if (location.state.val['Moq'].length > 2) {
				setChecked(false);
				inputRef.current.value = location.state.val['Moq'].length;
			} else if (location.state.val['Moq'].length === 2 && location.state.val['Moq'][1] / location.state.val['Moq'][0] === 2) {
				setChecked(false);
			}
		}
		setInputVal(input);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	async function getShipFrom() {
		try {
			const posts = await DataStore.query(OverseasShipping);
			const unique = [...new Set(posts.map(item=>item.ShipFrom))];
			setShipFrom(unique);
		} catch (error) {
			console.log('Error retrieving inv', error);
		}
	};

	const createItem = async () => {
		if (location.state.val !== undefined) {
				await updateItem();
		} else {
			await DataStore.save(new OverseasShipping({
				"Product":inputVal.Product,"Category":inputVal.Category,"Code":inputVal.Code,"ZH":inputVal.ZH,"Size":inputVal.Size,
				"Expiry":inputVal.Expiry,"Unit":inputVal.Unit,"Note":inputVal.Note,"Area":inputVal.Area,"Price":inputVal.Price,
				"Moq":inputVal.Moq,"NW":inputVal.NW,"GW":inputVal.GW,"CartonSize":inputVal.CartonSize, "ShipFrom":inputVal.ShipFrom
			}));
		}				
		navigate('/inventory');		 
	};

	async function updateItem() {
		const original = await DataStore.query(OverseasShipping, location.state.val);
		await DataStore.save(
			OverseasShipping.copyOf(original, updated => {
				updated.Product=inputVal.Product;
				updated.Category=inputVal.Category;
				updated.Code=inputVal.Code;
				updated.ZH=inputVal.ZH;
				updated.Size=inputVal.Size;
				updated.Expiry=inputVal.Expiry;
				updated.Unit=inputVal.Unit;
				updated.Note=inputVal.Note;
				updated.Area=inputVal.Area;
				updated.Price=inputVal.Price;
				updated.Moq=inputVal.Moq;
				updated.NW=inputVal.NW;
				updated.GW=inputVal.GW;
				updated.CartonSize=inputVal.CartonSize;
				updated.ShipFrom=inputVal.ShipFrom;
			})
    	);
 	};

	function getInput(e,id, areaName) {
		let newVal = {...inputVal};
		let val;
		let min = 0;
		switch(id) {
			case 'Min':
				let multiple = newVal.Moq.length;
				val = parseInt(e.target.value);
				newVal.Moq[0] = val;
				if(!checked && val>0) {
					for(let i =0; i < multiple; i++){
						priceSet[i] = min += newVal.Moq[0];					
					}
					setPriceSet(newVal.Moq)
				} else if (val < 0) {
					inputMin.current.value = '';
					newVal.Moq[0] = null;
				}
				break;
			case 'Max':
				val = parseInt(e.target.value);
				if(val > 0) {
					newVal.Moq[1] = val;
				} else {
					inputRef.current.value = '';
				}	
				break;
			case 'Multiplier':
				val = parseInt(e.target.value);
				setPriceSet([])
				if(val >=0 ) {
					for(let i =0; i < val; i++){
						priceSet[i] = min += newVal.Moq[0];
						newVal.Moq = priceSet;
						setPriceSet(newVal.Moq);
					}
				} else {
					newVal.Moq = [newVal.Moq[0]];
					inputRef.current.value = '';
				}
				break;
			case 'Expiry':
				val = parseInt(e.target.value);
				newVal[id] = val || null;
				break;
			case 'NW':
				val = parseFloat(e.target.value);
				newVal[id] = val;
				break;
			case 'GW':
				val = parseFloat(e.target.value);
				newVal[id] = val;
				break;
			case 'ShipFrom':
				val = e.target.value;
				newVal[id] = val || null;
				newVal.Price = {};
				if (val && val !== 'TW') {
					newVal['Area'] = [val];
				} else {
					newVal['Area'].splice(0,newVal['Area'].length);//clear area when !val
				}
				break;
			case 'Area':	
				if (e.target.checked !== undefined) {
					if (e.target.checked) {
						newVal[id].push(areaName);
					} else {
						let index = newVal[id].indexOf(areaName);
						if (index !== -1) {
							delete newVal.Price[areaName];
							newVal[id].splice(index, 1);							
						}
					}
				}
				break;
			case 'Price':
				val = e.target.value;
				const parts = val.split(".");
				if (val < 0) {
					val = 0;
				}
				if (parts.length > 1 && parts[1].length > 2) {
					parts[1] = parts[1].substring(0, 2);
					val = parts.join(".");
					newVal[id][areaName] = val;
				} else {
					newVal[id][areaName] = val;
				}
				break;
			default:
				val = e.target.value;
				newVal[id] = val || null;
				break;
		}
		setInputVal(newVal);
	};
	
	function onBlur(e,id) {
		let newVal = {...inputVal};
		if(newVal.Moq[1]  < newVal.Moq[0]) {
			setQtyError(true);
		} else {
			setQtyError(false);
		}
		setInputVal(newVal);
	};

	function inputFormat(id) {
		inputVal.Area = inputVal.Area || [];
		let inVal = (location.state.val !== undefined) ? location.state.val[id] : null;
			switch(id) {
				case 'Area':		
					return (
						<FormControl component="fieldset">
							{inputVal.ShipFrom === 'TW'?
							(
								<FormGroup aria-label="position" row>	
								{area.map((i,index)=>{
									return (
										<FormControlLabel
											control={
												<Checkbox name={i} 
													checked={inputVal.Area && inputVal.Area.length > 0 ? inputVal.Area.includes(i) : false}
													onChange={(e)=>getInput(e,'Area',i)}
												/>
											}
											label={i}
											key={i+index}
											sx={{paddingLeft: '10px'}}
											labelPlacement="end"
										/>
									)
								})}
								</FormGroup>
							):(
								<FormGroup sx={{fontSize: 'medium'}}> {inputVal.ShipFrom}</FormGroup>
							)
							}
						</FormControl>
					)
				case 'Price':
					return (
						<>
						{inputVal.ShipFrom === 'TW'?(
							area.map((i,index)=>{
								return (
								<Grid container key={i+index}>
									<Grid item xs={8}>
											<SoftTypography fontSize="small">{i}</SoftTypography>
										</Grid>
										<Grid item xs={4} padding={0.3}>
											<SoftInput type="number" step='0.01' size="small" onChange={(e)=>getInput(e, 'Price', i)} 
												disabled={!inputVal.Area.includes(i)} value={!inputVal.Area.includes(i)? '' : inputVal.Price[i]}></SoftInput>
										</Grid> 
								</Grid>
								)						
							})
						):(
							<Grid container>
								<Grid item xs={8}>
									<SoftBox fontSize="medium">{inputVal.ShipFrom}</SoftBox>
								</Grid>
								<Grid item xs={4} padding={0.3}>
									{inputVal.ShipFrom?
									(
										<SoftInput type="number" step='0.01' size="small" value={inputVal?inputVal.Price[inputVal.ShipFrom]:0} onChange={(e)=>getInput(e, 'Price', inputVal.ShipFrom)}></SoftInput>
									):('')
									}	
								</Grid> 
							</Grid>
						)}
						</>
					)
				case 'Moq':
					return (					
						<>
							<Grid paddingTop={2}>
								<Stack direction="row" spacing={1} alignItems="center">
									<Typography fontSize="small">{t('edit.Multiple')}</Typography>
									<Switch checked={checked} onChange={handleChange} />
									<Typography fontSize="small">{t('edit.Moq')}</Typography>
								</Stack>
							</Grid>
							<Grid container paddingTop={2}>
								<Grid item xs={3}>
									<SoftTypography fontSize="small">
										{t('edit.Min')}
									</SoftTypography>
									<SoftInput type="number" p={2} display="flex" size="small" inputRef={inputMin} onChange={(e)=>getInput(e,'Min')} onBlur={(e)=>onBlur(e, 'Min')} ></SoftInput>
								</Grid>
								<Grid item xs={3} >
									<SoftTypography fontSize="small">
										{checked? (t('edit.Max')):(t('edit.Multiplier'))}
									</SoftTypography>
									<SoftInput type="number" p={2} display="flex" size="small" inputRef={inputRef} onChange={(e)=>getInput(e, checked? ('Max'):('Multiplier'))} onBlur={(e)=>onBlur(e, checked? ('Max'):('Multiplier'))} ></SoftInput> {/*value={checked?parseInt(inputVal.Moq[1]):''} value={inputVal.Moq[0]>inputVal.Moq[1]? '':inputVal.Moq[1]} */} {/*onBlur={(e)=>onBlur(e, checked? ('Max'):('Multiplier'))} */}
								</Grid>
								<Grid item xs={6} paddingLeft={1} paddingTop={4}>
									<SoftTypography fontSize="small">{checked? '':(inputVal.Moq.join(', '))}</SoftTypography>
									<FormHelperText sx={{fontSize: 'xs'}} error={qtyError} id="outlined-weight-helper-msg" hidden={!(qtyError && checked)}>{t('edit.Max should > Min')}</FormHelperText>
								</Grid>
							</Grid>
						</>
					)
				case 'Note':
					return (
						<Grid>
							<TextareaAutosize
								maxRows={3}
								style=	{{width: '100%'}}
								onChange={(e)=>getInput(e,id)}
								defaultValue={inVal}
							/>
						</Grid>
						)
				case 'Category':
					return (
						<Box sx={{ minWidth: 120 }}>
							<FormControl fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									defaultValue={inVal || 'Uniform'} 
									onChange={(e)=>getInput(e,id)}
								>
									{location.state.category.map((i,index)=>{
										return (<MenuItem value={i} key={i+index}>{i}</MenuItem>)
									})}			
								</Select>
							</FormControl>
						</Box>
					)
				case 'Product':
				case 'Code':
					return(
						<Grid>
							<SoftInput p={2} key={id} defaultValue={inVal} display="flex" size="medium" placeholder={t('inventory.'+id)} onChange={(e)=>getInput(e,id)}></SoftInput>
							<SoftTypography fontSize="small" color="error" hidden={inputVal.Product}>{t('edit.*required')}</SoftTypography>
						</Grid>
					)
				case 'NW':
				case 'GW':
				case 'Expiry':
					return (
						<Grid>
							<SoftInput type="number" p={2} key={id} defaultValue={inVal} display="flex" size="medium" placeholder={t('inventory.'+id)} onChange={(e)=>getInput(e,id)}></SoftInput>
						</Grid>
					)
				case 'ShipFrom':
					return (
						<Grid>
							<Stack spacing={2}>
								<Autocomplete
									id="ship-from"
									freeSolo
									defaultValue={inVal}
									options={shipFrom.map((option) => option)}
									onBlur={(e, option)=>getInput(e, id, option)}
									renderInput={(params) => <TextField placeholder={t('inventory.'+id)} {...params} />}
								/>
							</Stack>
						</Grid>
					)	
				default:
					return (
						<Grid>
							<SoftInput p={2} key={id} defaultValue={inVal} display="flex" size="medium" placeholder={t('inventory.'+id)} onChange={(e)=>getInput(e,id)}></SoftInput>
						</Grid>
					)
			}
	};
	function discardItem() {
		navigate('/inventory');	
	};

	function renderRow() {
		return (
			location.state.item.slice(1,16).map((i,index)=>{
				return (
					<Grid item xs={12} md={6} key={i+index}>
						<Grid item xs={12} md={8}>
							{i.id==='Product'?(
								<SoftTypography fontSize="medium" fontWeight="bold" >
									<Tooltip title={t('edit.info')}>
										<Icon color='dark'>info</Icon>
									</Tooltip>
									{t('inventory.'+i.id)}
								</SoftTypography>
							):(
								<SoftTypography fontSize="medium" fontWeight="bold" >
									{t('inventory.'+i.id)}
								</SoftTypography>
							)}
						</Grid>
						<Grid item xs={12} md={8} paddingBottom={2}>
							{inputFormat(i.id)}
						</Grid>
					</Grid>
				)
			})
		);
	};

	return(
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox p={2} mx={3} display="flex" justifyContent="center">
				<Grid container>
					{renderRow()}
				</Grid>
			</SoftBox>
			<SoftBox p={2} mx={3} display="flex" justifyContent="left">
				<Tooltip>
					<SoftButton sx={{marginRight:2}} aria-label="delete" color='error' size="small" onClick={handleClickOpen}>
						{t('edit.Discard')}
					</SoftButton>
				</Tooltip>
				<Tooltip>
					<SoftButton aria-label="save" color='success' size="small" onClick={createItem} disabled={(qtyError && checked) || (inputVal.Product === null || inputVal.Code === null)} >
						{t('edit.Save')}
					</SoftButton>
				</Tooltip>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{t('edit.This is going to delete everything.')}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{t('Are you sure?')}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} autoFocus>{t('No')}</Button>
						<Button onClick={discardItem} >{t('Yes')}</Button>
					</DialogActions>
				</Dialog>
			</SoftBox>
		</DashboardLayout>
	);
}

export default Edit;